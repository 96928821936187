import * as React from 'react';
import {
  TeliaHeading,
  TeliaP,
  TeliaRow,
  TeliaLink,
} from '@teliads/components/react';
import { Layout, Grid } from '../components/Layout';
import '../styles/done.scss';
import { PageProps } from 'gatsby';
import {
  ContentfulPage,
  ContentfulStaticTranslations,
} from '../models/ContentfulTypes';
import { useStaticTranslations } from '../hooks/useStaticTranslations';

interface Props extends PageProps {
  pageContext: { page: ContentfulPage; contentfulId: string; language: string };
}

const Done = ({ pageContext: { page, contentfulId, language } }: Props) => {
  const content = useStaticTranslations();
  const wifiTipsTranslation = content[language].nodes?.find(
    node =>
      node.contentful_id === ContentfulStaticTranslations.WifiTipsAndTricks
  );
  const rateGuideTranslation = content[language].nodes?.find(
    node => node.contentful_id === ContentfulStaticTranslations.rateThisGuide
  );
  return (
    <Layout contentfulId={contentfulId} title={page.title}>
      <Grid>
        <TeliaRow className="column center gap padding--top">
          {page.photo && (
            <img src={page.photo?.file?.url} className="done__picture" />
          )}
          <TeliaHeading tag="h1" variant="title-500">
            {page.title}
          </TeliaHeading>
          <TeliaP>{page.heading?.heading}</TeliaP>
        </TeliaRow>
        <TeliaRow className="padding--top center telia-row gap--sm">
          <TeliaLink
            silent
            disableVisited
            target="_blank"
            href={wifiTipsTranslation?.link ? wifiTipsTranslation?.link : '#'}
          >
            <article className="flex column center tips-link">
              <img src="/tips-icon.svg" />
              <TeliaP>{wifiTipsTranslation?.title}</TeliaP>
            </article>
          </TeliaLink>
        </TeliaRow>
        {/* <TeliaRow className="center padding--vertical gap--sm">
          <TeliaP>{page.subHeading?.subHeading}</TeliaP>
          <TeliaLink variant="text">{page.nextButtonText}</TeliaLink>
        </TeliaRow> */}
      </Grid>
    </Layout>
  );
};

export default Done;
